<template>
  <Member
    title="Dr.ssa Simonetta Massaron"
    description="Clinical Data Manager"
    text="Dal mese di settembre 1995 al mese di settembre 1997: vincitrice di due borse di studio presso la Divisione di Medicina Nucleare dell’Istituto Nazionale per lo Studio e la Cura dei Tumori di Milano.
    
Dal mese di ottobre 1997 al mese di agosto 2000: segretaria scientifica presso la Divisione di Oncologia Medica A dell’Istituto Nazionale per lo Studio e la Cura dei Tumori di Milano.

Dal mese di ottobre 2000 al mese di febbraio 2001: assistente di ricerca presso la divisione di Oncologia Medica ed Oncoematologia dell’Istituto Europeo di Oncologia di Milano.

Dal mese di dicembre 2004 al mese di novembre 2012: coordinatrice scientifica e data manager presso l’Unità Operativa di Chirurgia Generale e Mininvasiva dell’Istituto Clinico Humanitas (contratto a progetto ed assegno di ricerca dell’Università degli Studi di Milano).

Dal mese di novembre 2012 al mese di dicembre 2015: segretaria di ricerca presso la Direzione Scientifica dell’Istituto Clinico Humanitas.

Dal mese di novembre 2012 al mese di aprile 2014: consulente, in qualità di coordinatrice scientifica e data manager presso l’Unità Operativa di Chirurgia Generale e Mininvasiva dell’Istituto Clinico Humanitas.

Dal mese di aprile 2014 al mese di dicembre 2015: consulente, in qualità di data manager presso l’Unità Operativa di Chirurgia Toracica dell’Istituto Clinico Humanitas.

Dal mese di gennaio 2016 ad oggi: coordinatrice scientifica e data manager presso l’Unità Operativa di Chirurgia Gastroenterologica dell’Ospedale San Raffaele.

Dall’anno 2012 a tutt’oggi: revisore per le seguenti riviste scientifiche: Journal of Pain Research, Open Access Surgery."
    :formation="[
      'Maturità in Perito Aziendale e Corrispondente in Lingue Estere, luglio 1986, Istituto Fabio Besta, Milano, votazione 50/60',
      'Diploma di Laurea in Scienze Biologiche conseguito il 23/02/95, Università Statale di Milano, votazione 100/110. Tesi dal titolo: La ß2 microglobulina sierica in pazienti affetti da malattie linfoproliferative maligne',
      '',
      '',
    ]"
    publication=""
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Massaron",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
